<script lang="ts" setup>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import SvgIcon from "../SvgIcon.vue";

const importAction = ref<boolean>(false);
const tmp = ref<string | null | File>(null);
const emit = defineEmits(["update:modelValue", "updateBanner", "editMode"]);

const fileImageInput = ref<HTMLInputElement | null>(null)
const showEditBannerDropdown = ref<boolean>(false);

const props = defineProps({
  modelValue: {
    type: null,
  },
  showEditBannerButton: {
    type: Boolean,
    default: false,
  },
});

const removeLogo = () => {
  resetImage();
  if (fileImageInput.value) {
    fileImageInput.value.value = "";
  }
  importAction.value = false;

  tmp.value = null;
  emit("update:modelValue", null);
  emit("updateBanner");
  emit("editMode", false);
  showEditBannerDropdown.value = false;
};

const resetImage = () => {
  image.value = { src: null, type: null };
};

onMounted(() => {

  nextTick(() => {
    tmp.value = props.modelValue;
  });

});

const width = computed(() => {
  const div = document.getElementById("header_container");
  if(!div) return 1259;
  const rect = div.getBoundingClientRect();
  return rect.width;
});

const height = computed(() => {
  const div = document.getElementById("header_container");
  if(!div) return 1259;
  const rect = div.getBoundingClientRect();
  return rect.height;
});
const initLoad = ref(true);

watch(() => props.modelValue, () => {
  if (initLoad.value) {
    tmp.value = props.modelValue
    initLoad.value = false
  }
});

const cropperRef = ref();
const image = ref<{ src: string | null, type: string | null }>({ src: null, type: null });

const uploadImage = (event: any) => {
  const { files } = event.target;
  if (files && files[0]) {
    if (image.value.src) {
      resetImage();
    }
    const file = files[0];
    const fileUrl = URL.createObjectURL(file);
    const img = new Image();
    img.onload = () => {
      let targetWidth = 1259;
      let scale = targetWidth / img.naturalWidth;
      let targetHeight = img.naturalHeight * scale;

      const canvas = document.createElement('canvas');
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
        image.value.src = canvas.toDataURL(file.type);
        image.value.type = file.type;
      }
      URL.revokeObjectURL(fileUrl);
    };
    img.src = fileUrl;
    showEditBannerDropdown.value = false;
    emit("editMode", true);
    event.target.value = "";

  }
};


const cropImage = () => {
  if (cropperRef.value) {
    const { canvas } = cropperRef.value.getResult();
    if (canvas) {
      canvas.toBlob((blob: any) => {
        if (blob) {
          const file = new File([blob], `cropped-image-${Date.now()}.png`, {
            type: 'image/png',
            lastModified: Date.now(),
          });
          if (tmp.value) {
            URL.revokeObjectURL(tmp.value as string);
          }

          tmp.value = URL.createObjectURL(file);

          emit("update:modelValue", file);
          emit("updateBanner");
          resetImage();
        }
      }, 'image/png');
    }

    if (importAction.value) {
      importAction.value = false;
    }
  }
};

const resetAfterDownload = () => {
  importAction.value = false;
  resetImage();
  emit("editMode", false);
};


const zoom = () => {
  if (cropperRef.value) {
    cropperRef.value.zoom(1.1);
  }
};

const dezoom = () => {
  if (cropperRef.value) {
    cropperRef.value.zoom(0.9);
  }
};

const editIcon = ref()


/** Expose Ref to Parent **/
defineExpose({ editIcon, fileImageInput })

const dropdownPosition = ref({ top: 0, left: 0 });

watch(showEditBannerDropdown, (newVal) => {
  if (newVal && editIcon.value) {
    const rect = editIcon.value.getBoundingClientRect();
    dropdownPosition.value = { top: rect.bottom, left: rect.left - 200 };
  }
});

const eventToggleOffDropdown = (event: FocusEvent) => {
  if (event?.relatedTarget instanceof HTMLElement) {
    if (event.relatedTarget.classList.contains("import_button")|| event.relatedTarget.classList.contains("delete_banner")) {
      return;
    }
  }
  showEditBannerDropdown.value = false
};
</script>


<template>
  <div class="gap-[4px] flex flex-col w-full">
    <button v-if="showEditBannerButton || showEditBannerDropdown" ref="editIcon" class="edit_circle absolute right-[32px] bottom-[8px]"
      @click="showEditBannerDropdown = !showEditBannerDropdown" @focusout="eventToggleOffDropdown">
      <SvgIcon name="edit" class="h-[18px] w-[18px]"></SvgIcon>
    </button>
    <ClientOnly>
      <Teleport to="body">
        <div class="dropdown_account" :class="[showEditBannerDropdown ? 'flex flex-col gap-2' : 'hidden']"
          :style="{ top: dropdownPosition.top + 'px', left: dropdownPosition.left + 'px' }">
          <button class="button_secondary_default import_button">
            <label class="cursor-pointer flex items-center gap-1">
              <SvgIcon name="upload-v2" class="h-[16px] w-[16px]"></SvgIcon>

              <span class="body_med text-left">Importer une nouvelle bannière</span>
              <input id="file-input" type="file" ref="fileImageInput" accept="image/*" style="display: none;"
                @change="uploadImage" />

            </label>
          </button>

          <button class="button_secondary_cancel_default flex items-center gap-1 delete_banner" @click="removeLogo">
            <SvgIcon name="delete_trash" class="h-[16px] w-[16px]"></SvgIcon>
            <span class="body_med text-left">Supprimer la bannière</span>
          </button>
        </div>
      </Teleport>
    </ClientOnly>
    <div v-if="image.src">
      <div :style="{ height: height + 'px' }">
        <!-- Zone du Cropper -->
        <Cropper ref="cropperRef" backgroundClass="cropper" :src="image.src" :stencil-props="{
          handlers: {},
          movable: false,
          resizable: false,
        }" 
        :stencil-size="{
          width: width,
          height: height
        }" :canvas="{
          width: 1200,
          height: 176,
        }" imageRestriction="none"/>
        <!-- Boutons de contrôle -->
        <div class="modal-buttons">
          <div class="banner_vertical-buttons">
            <div class="square-button" @click="zoom()">
              <SvgIcon name="plus" class="w-4 h-4 flex items-center" />
            </div>
            <div class="square-button" @click="dezoom()">
              <SvgIcon name="checkbox-minus" class="w-4 h-4" />
            </div>
          </div>
          <div class="banner_buttons-bottom">
            <div class="banner_tag-button" @click="resetAfterDownload()">Annuler</div>
            <div class="banner_tag-button" @click="cropImage()">Enregistrer</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@use "assets/scss/texts";

.edit_circle {
  @apply flex justify-center items-center;
  background:  var(--white-main);
  border-radius: 50%;
  color: var(--blue-medium);
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
}

.dropdown_account {
  @apply absolute justify-start right-0 rounded-[6px] mr-[30px] p-[12px];
  background: var(--white-main);
  width: 250px;
  box-shadow: var(--shadow-hard);
  z-index: 50;
  margin-top: 10px;
}

.cropper {
  min-height: 100%;
}

.cropper {
  background: white;
}

.cropper .vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: white;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: auto;
}

.drop_area {
  @apply flex flex-col items-center cursor-pointer h-[123px] w-full;
  transition: 0.2s ease;
  border-style: dashed;
  border-width: 2px;
  border-radius: 16px;

  &[data-active="true"] {
    background: var(--grey-light);
  }

  &:hover {
    cursor: pointer;
    background: var(--grey-light);
  }
}

.upload-label {
  @extend .body_med;
  @apply flex flex-col items-center justify-center px-[16px] py-[28px] w-full h-full;
  cursor: pointer;

  span {
    @apply text-center;
    color: var(--text-secondary-color);
  }

  input[type="file"]:not(:focus-visible) {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .text_underlined {
    text-decoration: var(--text-secondary-color) underline;
    text-decoration: underline;
    color: var(--primary-blue-color);
  }
}

.banner_vertical-buttons {
  position: absolute;
  left: 35px;
  top: 52px;
  transform: translateY(-50%);
  z-index: 1000;

}

.vertical-buttons-right {
  position: absolute;
  right: 0px;
  top: 10px;
  transform: translateX(-10px);
  display: flex;
  gap: 4px;
}

.banner_buttons-bottom {
  position: absolute;
  right: 25px;
  bottom: 8px;
  transform: translateX(-10px);
  display: flex;
  gap: 4px;
}


.square-button {
  background: var(--grey-lightest);
  color: var(--text-secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background 0.5s;
  border-radius: 20px;

  &:hover {
    background: #ebe1dc;
  }
}

.banner_tag-button {
  background: var(--grey-lightest);
  color: var(--text-secondary-color);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.banner_tag-button:hover {
  background-color: #ebdfd8;
}
</style>

