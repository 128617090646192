<script setup lang="ts">
import SmallDropdown from "~/components/Common/Dropdowns/SmallDropdown.vue";
import UserIcon from "~/components/Header/UserIcon.vue";
import AccountDropdown from "~/components/Header/AccountDropdown.vue";
import { useAuthStore } from "~/store/auth/auth.store";
import SvgIcon from "../Common/SvgIcon.vue";
import { useCompaniesStore } from "~/store/companies/companies.store";
import EditFileUploadHomeBanner from "~/components/Common/Upload/EditFileUploadHomeBanner.vue";
import { useCreateCompaniesStore } from "~/store/companies/create-company.store";
const createCompaniesStore = useCreateCompaniesStore();
const { banner, modeEditBanner } = storeToRefs(createCompaniesStore);
const show_account = ref<boolean>(false);
const user_icon = ref();
const authStore = useAuthStore();
const dropdownPosition = ref({ top: 0, left: 0 });
const companiesStore = useCompaniesStore();
const { company_infos } = storeToRefs(companiesStore);
const showEditBannerButton = ref<boolean>(false);
const editMode = ref(false);

declare global {
  interface Window {
    Beacon: any;
  }
}

function openHelpscout() {
  window.Beacon("open");
}

const eventToggleOffDropdown = (event: FocusEvent) => {
  if (event?.relatedTarget instanceof HTMLElement) {
    if (event.relatedTarget.classList.contains("disconnect_button")) {
      return;
    }
  }
  show_account.value = false;
};

watch(show_account, (newVal) => {
  if (newVal) {
    const rect = user_icon.value.$el.getBoundingClientRect();
    dropdownPosition.value = { top: rect.bottom, left: rect.left - 150 };
  }
});


onMounted(async () => {
  await nextTick(async () => {
    await companiesStore.fetchCompanyInfos();
  });
});

const heightHeader = computed(() => {
  if(company_infos?.value?.welcome_message){
    return company_infos?.value?.welcome_message?.length < 330 ? "176px" : "96px";
  }
  return "176px";
});

const updateWidth = () => {
  windowWidth.value = window.innerWidth;
};

onMounted(() => {
  window.addEventListener("resize", updateWidth);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateWidth);
});

const updateBanner = async () => {
  if(company_infos.value?.company_id){
    if (banner.value && banner.value instanceof File) {

      await createCompaniesStore.convertCompanyBanner(banner.value as File);
    }
    if (banner.value instanceof File || !banner.value) {

      await createCompaniesStore.updateCompanyBanner(company_infos.value?.company_id);
      await companiesStore.fetchCompanyInfos();
    }
  }
};


const windowWidth = ref(1024);

const modeEdit = (bEditMode: boolean) => {
  editMode.value = bEditMode;
  modeEditBanner.value = bEditMode;
}
</script>

<template>
  <div class="relative">
    <nav class="w-full"
    @mouseover="showEditBannerButton = true" @mouseleave="showEditBannerButton = false"
    id="header_container"
    :style="{
    '--banner-url': company_infos?.banner_url ? `url(${company_infos.banner_url})` : 'none',
    height: windowWidth >= 1024 ? company_infos?.banner_url || modeEditBanner ? heightHeader : company_infos?.welcome_message && company_infos?.welcome_message?.length > 330 ? '63px' : '117px' : '100%'
  }"
    :class="windowWidth >= 1024 ? 'header' : 'header-mobile border-b-[1px] border-stroke-color px-6 py-4 '">
      <div class="flex flex-row items-center justify-between">
        <div class="w-full lg:hidden">
          <span class="heading_3_demi">Bienvenue 👋</span>
        </div>
        <div 
        class="flex flex-row w-full  items-center gap-[8px] lg:gap-[16px] justify-end"
        :class="{'absolute right-[32px] top-[8px] z-10' : windowWidth >= 1024}">
          <SvgIcon
            class="button_tootip"
            name="tooltip"
            :style="{ height: '24px' }"
            @click="openHelpscout"
          ></SvgIcon>
          <SmallDropdown></SmallDropdown>
          <UserIcon
            v-if="authStore.isLoggedIn()"
            @click="show_account = !show_account"
            @focusout="eventToggleOffDropdown"
            ref="user_icon"
          ></UserIcon>
        </div>
        <EditFileUploadHomeBanner v-model="banner" @updateBanner="updateBanner" @edit-mode="modeEdit" :showEditBannerButton="showEditBannerButton" v-if="authStore.isSimbelAdmin && windowWidth >= 1024"></EditFileUploadHomeBanner>
      </div>
    </nav>
    <Teleport to="body">
      <AccountDropdown
        v-if="authStore.isLoggedIn()"
        @close="show_account = false"
        :show_account="show_account"
        :style="{ top: dropdownPosition.top + 'px', left: dropdownPosition.left + 'px' }"
      ></AccountDropdown>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">

.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: var(--banner-url);
  /* background-image: url("./images.jpeg"); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;
  z-index: -1;
}
.header {
  @apply flex flex-col  gap-[18px] relative;
  z-index: 0;
}

.header-mobile {
  @apply flex flex-col gap-[18px] relative;
  z-index: 0;
}

.button_tootip {
  @apply cursor-pointer text-secondary-color ;

  &:hover {
    color: var(--blue-deep-dark);
  }
}
</style>
